import { BASE_URL } from "../../../global";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

let global_datasheetReadings = null;

async function executeQuery(query) {
  let data = {
    query: query,
  };

  try {
    let res = await axiosWithToken.post(BASE_URL + `dynamic`, data);
    return res;
  } catch (err) {
    console.error("Failed to execute query: ", query);
    console.error(err);
  }
}

async function deleteTable(tableName) {
  // Generate the DROP TABLE query dynamically
  const query = `DROP TABLE IF EXISTS ${tableName}`;

  // Execute the DROP TABLE query
  await executeQuery(query);
}

async function createTable(tableName, columns) {
  // Generate the CREATE TABLE query dynamically
  const query = `CREATE TABLE IF NOT EXISTS ${tableName} (id INT, ${columns
    .map((col, index) => `col${index + 1} CHAR(100)`)
    .join(", ")})`;

  // Execute the CREATE TABLE query
  await executeQuery(query);
}

async function insertData(tableName, data) {
  // Generate the INSERT INTO query dynamically
  const columns = Object.keys(data[0]).map((col, index) => `col${index + 1}`);
  columns.unshift("id");
  const values = data.map((row, index) => {
    return (
      `${index + 1}, ` +
      Object.values(row)
        .map((value) => `'${value}'`)
        .join(", ")
    );
  });
  const query = `INSERT INTO ${tableName} (${columns.join(
    ", "
  )}) VALUES (${values.join("), (")})`;

  // Execute the INSERT INTO query
  await executeQuery(query);
}

function separateDataByTableId(datasheetReadings) {
  const separatedData = {};

  datasheetReadings.forEach((reading) => {
    const tableId = reading[0];
    const thirdValue = reading[2];

    if (thirdValue.includes("_unit_") || thirdValue.includes("_rh_")) {
      // Skip the list if the third value contains '_unit_' or '_rh_'
      return;
    }

    if (separatedData[tableId]) {
      separatedData[tableId].push(reading.slice(1));
    } else {
      separatedData[tableId] = [reading.slice(1)];
    }
  });

  return separatedData;
}

async function fetchSteps(instrumentId) {
  return new Promise((resolve, reject) => {
    axiosWithToken
      .get(
        BASE_URL + `datasheetTemplate?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        if (res?.data[0]?.config) resolve(JSON.parse(res?.data[0]?.config));
        else resolve(null);
      })
      .catch((err) => {
        console.error("Something Went Wrong while fetching datasheetTemplate!");
        reject(err);
      });
  });
}

async function setValToTable(datasheetId, source, staticTables, data) {
  source = source.split(".");
  let tableId, columnId, rowId;
  if (source[0].includes("tid")) {
    tableId = source[0].split("tid")[1];
  }
  if (source[1].includes("col")) {
    columnId = source[1].split("col")[1];
  }
  if (source[2].includes("row")) {
    rowId = source[2].split("row")[1];
  } else if (source[2].includes("all")) {
    rowId = "all";
  }

  // 1. update datasheet reading row
  let rowCount = 0;
  let index = 0;
  for (const element of global_datasheetReadings) {
    if (element[0] == tableId) {
      rowCount += 1;
      if (rowId == "all" || rowCount == rowId) {
        // skip unit and row header rows
        if (element[2].includes("_unit_")) continue;
        if (element[2].includes("_rh_")) continue;

        // prepend $ and remove excessive $ if any
        let value = 0;
        if (data.length == 1) {
          value = data?.[0]?.["col1"] || 0;
        } else {
          value = data?.[index]?.["col1"] || 0;
        }
        element[columnId - 1 + 2] = "$" + value;
        //  replace all $ with only one $
        element[columnId - 1 + 2] = element[columnId - 1 + 2].replace(
          /\$+/g,
          "$"
        );
        index++;
      }
    }
  }

  // 2. update database dummy table values
  const dummyTableName = `t_${datasheetId}_${tableId}`;
  // let query = `UPDATE ${dummyTableName} SET col${Number(columnId) + 1} = '${
  //   data?.[0]?.["col1"]
  // }'`;
  // if (rowId !== "all") {
  //   query = query + ` WHERE id = ${rowId}`;
  // }
  // try {
  //   await executeQuery(query);
  // } catch (err) {
  //   console.error("Table not found, or 0 readings for destination table");
  // }

  if (rowId === "all") {
    // Update each row individually if rowId is 'all'
    let updates = data
      .map((entry, idx) => {
        return `UPDATE ${dummyTableName} SET col${Number(columnId) + 1} = '${
          entry["col1"]
        }' WHERE id = ${idx + 1}`;
      })
      .join("; ");
    updates += ";"

    try {
      await executeQuery(updates);
    } catch (err) {
      console.error("Error updating rows in table:", err);
    }
  } else {
    // Update a specific row if rowId is not 'all'
    const query = `UPDATE ${dummyTableName} SET col${Number(columnId) + 1} = '${
      data?.[0]?.["col1"]
    }' WHERE id = ${rowId}`;
    try {
      await executeQuery(query);
    } catch (err) {
      console.error(
        "Table not found, or 0 readings for destination table:",
        err
      );
    }
  }
}

async function updateDummyTables(
  datasheetId,
  action = "update",
  tableName = null
) {
  // seperate out data according to table ids
  let tables = separateDataByTableId(global_datasheetReadings);

  let table_keys = Object.keys(tables);
  for (let i = 0; i < table_keys?.length; i++) {
    let tableName = `t_${datasheetId}_${table_keys[i]}`;
    // delete table if exist
    await deleteTable(tableName);
    // create table in tables_id's asc order
    let dummy_cols = Array.from(
      { length: tables[table_keys[i]]?.[0].length },
      (_, i) => ""
    );

    await createTable(tableName, dummy_cols);
    // insert data into table
    await insertData(tableName, tables[table_keys[i]]);
  }
}

async function deleteDummyTables(datasheetId) {
  // seperate out data according to table ids
  let tables = separateDataByTableId(global_datasheetReadings);

  let table_keys = Object.keys(tables);
  for (let i = 0; i < table_keys?.length; i++) {
    let tableName = `t_${datasheetId}_${table_keys[i]}`;
    // delete table if exist
    await deleteTable(tableName);
  }
}

export default async function processDatasheetTemplate(
  datasheetReadings,
  instrumentId,
  staticTables,
  datasheetId
) {
  global_datasheetReadings = datasheetReadings;

  await deleteDummyTables(datasheetId);

  let steps = await fetchSteps(instrumentId);
  if (steps?.length > 0) {
    await updateDummyTables(datasheetId, "create");
  }
  for (let i = 0; i < steps?.length; i++) {
    let instructions = steps[i]?.instructions;
    let outputStream = steps[i]?.outputStream;
    for (let i = 0; i < instructions?.length; i++) {
      let query = instructions[i];
      if (query) {
        for (let j = staticTables?.length; j > 0; j--) {
          query = query.replaceAll(
            "t" + j,
            `t_${datasheetId}_${staticTables[j - 1].id}`
          );
        }
      }
      let res = await executeQuery(query);
      if (outputStream.includes("tid")) {
        await setValToTable(datasheetId, outputStream, staticTables, res?.data);
      }
    }
  }

  await deleteDummyTables(datasheetId)

  return global_datasheetReadings;
}
